<template>
  <div class="table-container" v-if="$can('view', 'HistoricoAlarmeAcesso')">
    <Spin v-if="busy" />
    <SearchableTable
      tableId="alarmHistoryReport"
      v-if="showTable"
      v-bind:items="filteredItems"
      v-bind:fields="fields"
      v-bind:multiColumnOrder="false"
      v-bind:clientSort="true"
      v-bind:pagination="pagination"
      v-bind:maxResult="maxResult"
      v-bind:searchEnabled="false"
      @loadNextPage="onLoadNextPage"
    />
  </div>
</template>

<script>
import SearchableTable from "@/components/searchable-table.vue";
import AlarmService from "@/services/alarm.js";
import Spin from "@/components/spin.vue";

/*
{
    "date_time_event": "2021-01-02T10:32:10-03:00",
    "alarm_value": "1.000",
    "system_event_id": 3,
    "system_sub_event_id": 12,
    "alarm": {
        "id": 4191,
        "name": "ALARME COIL 0 (coleta local)"
    },
    "user_email": "suporte@hitecnologia.com.br"
}
*/
function defaultData(self) {
  return {
    busy: false,
    showTable: true,
    myStartDate: null,
    myEndDate: null,
    //===========================
    items: null,
    fields: [
      {
        name: "name",
        title: "alarm",
        order_id: "alarm_name",
        parser(item) {
          return item.alarm.name || "-";
        }
      },
      {
        name: "date_time_event",
        title: "timestamp",
        order_id: "date_time_event",
        parser: function(item) {
          if (item && item.date_time_event) {
            return self.$dt.format(item.date_time_event);
          }
          return "";
        }
      },
      {
        name: "event",
        title: "event",
        order_id: "system_sub_event_name",
        parser(item) {
          let txt = (item.system_sub_event && item.system_sub_event.name) || "";
          if (!txt) {
            let evt = (
              self.$root?.config?.references.system_event_list || []
            ).find(({ id }) => id == item.system_event_id);
            let sev = ((evt && evt?.system_sub_event_list) || []).find(
              ({ id }) => id == item.system_sub_event_id
            );
            txt = (sev && sev?.name) || "";
          }
          return txt;
        }
      },
      {
        name: "user",
        title: "user",
        order_id: "user_email",
        parser: function(item) {
          let email =
            (item && item.user_email) || (item.user && item.user.email) || "";
          if (email.indexOf("@") >= 0) {
            return (email || "").split("@")[0] + "@";
          }
          return "-";
        }
      },
      // {
      //   name: "criteria",
      //   title: "criteria",
      //   parser: function(item, vm) {
      //     let vlr="-";
      //     let id = item.alarme_alarm_trigger_condition && item.alarme_alarm_trigger_condition.id || "";
      //     if (id){
      //       let condition=vm.$root.config.references.alarm_trigger_conditions.find(function(i){
      //         return i.id=id;
      //       });
      //       if (condition){
      //         vlr=condition.comparison_operator+item.alarm_limit;
      //       }
      //     }
      //     return vlr;
      //   },
      // },
      {
        name: "value",
        title: "value",
        order_id: "alarm_value",
        parser: function(item) {
          return item.alarm_value;
        },
        style: {
          "text-align": "right"
        }
      }
    ],
    next_page: null,
    curr_page: null,
    prev_page: null,
    sortOrder: null,
    maxResult: 0
  };
}

export default {
  name: "EquipmentAlarmHistoryDisplay",
  components: {
    SearchableTable,
    Spin
  },
  props: {
    equipment: {
      tyoe: Object,
      required: true
    },
    display: {
      type: Object,
      required: true
    },
    panelOptions: {
      type: Object,
      required: true
    },
    startDate: {
      type: Object,
      required: false,
      default: () => moment()
    },
    endDate: {
      type: Object,
      required: false,
      default: () => moment()
    },
    downloading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    alarmList: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function() {
    return defaultData(this);
  },
  computed: {
    pagination() {
      return !this.downloading &&
        "page_size" in this.$root.config.equipment_selection &&
        this.$root.config.equipment_selection.page_size
        ? "server"
        : "";
    },
    contract() {
      let user = this.$store.getters["user/loggedUser"];
      return (user && user.contract) || null;
    },
    isDirty: function() {
      return (
        this.startDate &&
        this.endDate &&
        this.startDate._d &&
        this.endDate._d &&
        (this.myStartDate != this.startDate._d ||
          this.myEndDate != this.endDate._d)
      );
    },
    showLine: function() {
      var options = this.panelOptions;
      if (options && "showLine" in options) {
        return options.showLine;
      }
      return false;
    },
    historyEnabledAlarmIdList() {
      return this.alarmList
        .filter(({ history_enabled }) => history_enabled)
        .map(({ id }) => id);
    },
    filteredItems() {
      let self = this;
      if (!this.historyEnabledAlarmIdList.length) return [];
      let lst = this.items || [];
      let device_id = self.$store.getters.deviceId;
      if (device_id && lst && lst.length) {
        let alarms = {};
        lst = lst.filter((i) => {
          let alarm =
            alarms[i.alarm.id] ||
            this.alarmList.find((alarm) => alarm.id == i.alarm.id) ||
            null;
          if (alarm) {
            alarms[i.alarm.id] = alarm;
          }
          return alarm && alarm.device_id == device_id;
        });
      }
      return lst.filter(
        ({ alarm }) => this.historyEnabledAlarmIdList.indexOf(alarm.id) >= 0
      );
    }
  },
  watch: {
    isDirty: {
      handler: function(n, o) {
        if (n) {
          this.fetchItems();
        }
      },
      immediate: true,
      deep: true
    },
    historyEnabledAlarmIdList() {
      this.fetchItems();
      // this.showTable = false;
      // this.$nextTick(() => {
      //   this.showTable = true;
      // });
    }
  },
  methods: {
    _fetch(query, url, resolve) {
      let self = this;
      self.busy = true;
      self.service.history(query, url).then((response) => {
        self.busy = false;
        if (response && typeof response == "object") {
          let items = [];
          // let items = self.items || [];
          let results = [];
          if ("results" in response && response.results.length) {
            // self.maxResult = response.count;
            // self.next_page = response.next_page;
            // self.prev_page = response.previous_page;
            // self.curr_page = url;
            // results = response.results;

            if (url) {
              items = self.items.concat(response.results);
            } else {
              items = response.results;
            }
            // todo: remove fix below when backend be fixed
            // at 20/04/2021 next_page is wrongly returned as http: and must be replaced
            // note it is import to get the protocol from api url, once you might be running
            // at localhost which document.protocol is http: too
            if (response.next_page) {
              self.next_page = response.next_page.replace(
                /http:/g,
                self.$root.config.api_url.split("/")[0]
              );
            }
            if ("next_page_token" in response) {
              // cursor based query
              self.maxResult = items.length; // nav pagination will only show if mas result is greater than the num of itens
            } else {
              self.maxResult = response.count;
            }
            // begin_test_block
            // items.splice(response.count, response.count);
            // items = items.map((i, x) => {
            //   i.message = x + 1 + " msg";
            //   return i;
            // });
            // end_test_block

            // todo: remove fix below when backend be fixed
            // at 20/04/2021 next_page is wrongly returned as http: and must be replaced
            // note it is import to get the protocol from api url, once you might be running
            // at localhost which document.protocol is http: too
            if (response.next_page) {
              self.next_page = response.next_page.replace(
                /http:/g,
                self.$root.config.api_url.split("/")[0]
              );
            }
            // if (response.prev_page) {
            //   self.prev_page = response.prev_page.replace(
            //     /http:/g,
            //     self.$root.config.api_url.split("/")[0]
            //   );
            // }
            // if (response.curr_page) {
            //   self.curr_page = response.curr_page.replace(
            //     /http:/g,
            //     self.$root.config.api_url.split("/")[0]
            //   );
            // }
          } else if (response.length) {
            self.maxResult = response.length;
            results = response;
          }
          // merge current list with results
          results.forEach(function(r) {
            if (r.id) {
              items = items.filter(function(i) {
                return i.id != r.id; // remove if it exists and so only latest version is considered
              });
            }
            items.push(r); // insert;
          });
          self.$set(self, "items", items);
          resolve(true);
        } else {
          if (self.prev_page) {
            self.curr_page = self.prev_page;
            self.prev_page = null;
          } else {
            self.curr_page = null;
            resolve(false);
          }
        }
      });
    },

    async fetch(query, url) {
      let self = this;
      if (query && !url) {
        if (this.pagination) {
          if (
            "page_size" in this.$root.config.equipment_selection &&
            this.$root.config.equipment_selection.page_size
          ) {
            query.page = 1;
            //query.page_size = this.$root.config.equipment_selection.page_size;
            query.max_results = this.$root.config.equipment_selection.page_size;
          }
        }
        // if (this.sortOrder && this.sortOrder.length){
        //   query.ordering=this.sortOrder.join(",");
        // }
      }

      return new Promise((resolve) => {
        self._fetch(query, url, resolve);
      });
    },

    fetchItems(URL) {
      let self = this;
      let url = URL || "";
      let query = null;
      if (!url) {
        if (
          !(
            this.startDate &&
            this.endDate &&
            this.startDate._d &&
            this.endDate._d
          )
        )
          return;
        this.myStartDate = this.startDate._d;
        this.myEndDate = this.endDate._d;
        query = {};
        query.contract_id = this?.contract?.id;
        query.start = moment(self.startDate)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss");
        query.end = moment(self.endDate)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss");
        if (this.historyEnabledAlarmIdList.length) {
          query.alarm_ids = this.historyEnabledAlarmIdList.join(",");
        } else {
          query.connector_id = this.equipment.id;
        }
      }

      this.fetch(query, url).then((ret) => {
        // parse them
        let items = this.items;
        this.$set(this, "items", items);
      });
    },
    reset() {
      Object.assign(this.$data, defaultData(this));
    },
    onLoadNextPage() {
      this.fetchItems(this.next_page || null);
    }
  },
  beforeCreate() {
    this.service = new AlarmService();
  },
  created: function() {
    // this.fetchItems();
  },
  mounted: function() {
    //this.fetchEquipmentDataAlarmsHistory();
  }
};
</script>

<style scoped>
.table-container {
  overflow-x: auto;
  height: 100%;
}
</style>
